<template>
  <div
    ref="target"
    :key="key"
    class="w-full relative flex flex-col gap-y-4 bg-white items-center px-5"
    :class="closeModal ? 'shadow-sm pt-14 pb-5 rounded-[10px]' : 'py-5'"
  >
    <h3>{{ title }}</h3>
    <div>
      <otp-input
        :count="6"
        :error="!!currentError"
        @complete="handlePinComplete"
      />
      <span
        v-if="currentError"
        class="text-failed text-sm flex items-center gap-x-[2px] mt-1"
        ><caution-icon class="fill-failed min-w-[24px]" />
        {{ currentError && currentError }}</span
      >
    </div>
    <div class="w-full flex flex-col gap-y-2 mt-5">
      <app-button
        variant="outlined"
        size="lg"
        :disabled="currentResendTimeout > 0"
        @click="resendOtp"
        >Resend OTP
        <span>{{
          currentResendTimeout > 0 ? `in ${currentResendTimeout}s` : ""
        }}</span>
      </app-button>
    </div>

    <button
      v-if="closeModal"
      type="button"
      class="absolute right-4 top-3"
      @click="handleCancel"
    >
      <close-icon class="w-4 h-4" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";

const props = defineProps<{
  title: string;
  resendTimeout: number | (() => number);
  error?: string | null | (() => string | null);
  closeModal?: () => void;
  resendOtp: () => Promise<void>;
}>();
const emit = defineEmits<{
  (e: "complete", payload: string): void;
}>();

const otp = ref("");
const key = ref(0);

const target = ref();

const handlePinComplete = (value: string) => {
  otp.value = value;
  emit("complete", otp.value);
};

const handleCancel = () => {
  if (props.closeModal) {
    props.closeModal();
  }
};

const currentResendTimeout = computed(() =>
  typeof props.resendTimeout === "function"
    ? props.resendTimeout()
    : props.resendTimeout,
);

const currentError = computed(() =>
  typeof props.error === "function" ? props.error() : props.error,
);
</script>
