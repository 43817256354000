// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorMessage = (err: any) => {
  if (err.name === "AxiosError") {
    const response = err.response?.data;
    if (response) {
      if (response?.error?.field_errors) {
        return (
          response?.error?.field_errors[0]?.field +
          response?.error?.field_errors[0]?.message
        );
      }

      return response?.error?.message || response?.message || err.message;
    }
    return err.message;
  }
  return "Unknown error occurred";
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOtpError = (err: any) => {
  if (
    err.response?.data?.error?.code === "INVALID_OTP" ||
    err.response?.data?.error?.code === "OTP_MISSING"
  ) {
    throw err;
  }
}