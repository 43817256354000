import apiClient, { bankingUrl, cryptoUrl } from "@/helpers/apiClient";
import { useModal } from '@/composables/use-modal'; 
import { Component, defineComponent, h, ref, shallowRef, unref } from "vue";
import LyncOtp from "@/components/shared/otp/LyncOtp.vue";

type LyncServiceType = "crypto" | "fiat" | "lync";

type RequestBody = Record<string, unknown> | FormData;

type LyncOtpViewFormatType = "component" | "modal";


export interface OtpVerificationProps {
  action: string;
  service: LyncServiceType;
  format: LyncOtpViewFormatType;
}

export interface ExecuteParams {
  body: RequestBody;
  service?: LyncServiceType;
  callback: (otp?:string) => Promise<void>
}

const requirementsUrl = {
  crypto: cryptoUrl("security/otp/requirements"),
  fiat: bankingUrl("security/otp/requirements"),
  lync: "v1/security/otp/requirements",
};

const generateUrls = {
  crypto: cryptoUrl("security/otp/generate"),
  fiat: bankingUrl("security/otp/generate"),
  lync: "v1/security/otp/generate",
};

export const useOtpVerification = (props:OtpVerificationProps) => {

  const { open, close } = useModal();
  const isOTPRequired = ref(false);
  const component = shallowRef<Component | null>(null);
  const error = ref<string | null>(null);
  const otpRequestData = ref<unknown>(null);
  const resendTimeout = ref(0);
  const service = ref(props.service);


  const checkRequirements = async (body:RequestBody): Promise<boolean> => {
    try {
       const response = await apiClient.post(
        requirementsUrl[service.value],
        {
          action: props.action,
          data: body,
        }
      );
      const otpRequired = response.data.data.otp_required;
      isOTPRequired.value = otpRequired;
      return otpRequired;
    } catch (error) {
      throw new Error("Failed to check OTP requirements");
    }
  };

  async function generateOTP(data: RequestBody) {
    try {
      const response = await apiClient.post(generateUrls[service.value], {
        action: props.action,
        data,
      });
      startResendTimer();
      return response.data;
    } catch (err) {
      throw new Error("Failed to generate OTP");
    }
  }

  function startResendTimer() {
    resendTimeout.value = 30;
    const timer = setInterval(() => {
      resendTimeout.value--;
      if (resendTimeout.value <= 0) {
        clearInterval(timer);
      }
    }, 1000);
  }

  async function resendOtp() {
    if (resendTimeout.value > 0 || !otpRequestData.value) return;

    try {
      await generateOTP(otpRequestData.value as never);
    } catch (err) {
      error.value = (err as never)["message"];
    }
  }

  

  const execute = async (params:ExecuteParams): Promise<void> => {
    try {
      
      if(params.service) {
        service.value = params.service
      }

      const otpRequired = await checkRequirements(params.body);
    
      otpRequestData.value = params.body;
      
      if (otpRequired) {
        await generateOTP(params.body);

        if(props.format === 'modal') {
          open({
            component: LyncOtp,
            size: "md",
            props: {
             title: "Please enter the OTP sent to your email",
              resendOtp,
              resendTimeout: () => unref(resendTimeout),
              error: () => unref(error),
              onComplete: async (otp: string) => {
                await params.callback(otp).catch((err) => {
                  if (
                    err.response?.data?.error?.code ===
                    "INVALID_OTP" || err.response?.data?.error?.code === "OTP_MISSING"
                  ) {
                    error.value = 'Invalid OTP';
                  }
                });
              },
              closeModal: close
            },
          });
          
        }else {
          component.value = 
          defineComponent({
            setup(props) {
              return () =>
                h(LyncOtp, {
                  ...props,
                  title: "Please enter the OTP sent to your email",
                  resendOtp,
                  resendTimeout: () => unref(resendTimeout),
              error: () => unref(error),
                  onComplete: async (otp: string) => {
                    await params.callback(otp).catch((err) => {
                      if (
                        err.response?.data?.error?.code ===
                        "INVALID_OTP" || err.response?.data?.error?.code === "OTP_MISSING"
                      ) {
                        error.value = 'Invalid OTP';
                      }
                    });
                  },
                });
            },
          })
        }
      } else {
        await params.callback()
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error:any) {
        console.error('OTP Verification process failed:', error);
        throw error;
      
    }
  };

  const reset = () => {
    close();
    isOTPRequired.value = false;
    error.value = null;
    component.value = null;
    resendTimeout.value = 0;
    otpRequestData.value = null;
  };

  return {
    execute,
    component,
    isOTPRequired,
    error,
    reset,
  };
};