/* eslint-disable @typescript-eslint/no-explicit-any */
import AppModal from '@/components/shared/AppModal.vue';
import { computed, defineComponent, h, shallowRef } from 'vue';
import type { Component } from 'vue';

// Modal configuration interface
export interface ModalConfig {
  component: string | Component;
  size: "sm" | "md" | "lg" | "xl"
  props?: Record<string, unknown>;
  events?: Record<string, (...args: any[]) => void>;
}

// Modal state interface
interface ModalState {
  isOpen: boolean;
  component: string | Component | null;
  props: Record<string, unknown>;
  size: "sm" | "md" | "lg" | "xl";
  events: Record<string, (...args: any[]) => void>;
}

// Create a reactive modal state
const modalState = shallowRef<ModalState>({
  isOpen: false,
  component: null,
  size: "md",
  props: {},
  events: {}
});

export const useModal = () => {
  // Open modal with specific configuration
  const open = (config: ModalConfig) => {
    modalState.value = {
      isOpen: true,
      component: config.component,
      size: config.size,
      props: config.props || {},
      events: config.events || {}
    };
  };

  // Close modal and reset state
  const close = () => {
    modalState.value = {
      isOpen: false,
      component: null,
      size: "md",
      props: {},
      events: {}
    };
  };

  // Provide a way to use modal state in components
  const useModalState = () => {
    return {
      isOpen: computed(() => modalState.value.isOpen),
      component: computed(() => modalState.value.component),
      props: computed(() => modalState.value.props),
      size: computed(() => modalState.value.size),
      events: computed(() => modalState.value.events)
    };
  };

  return {
    open,
    close,
    useModalState
  };
};

export const ModalWrapper = defineComponent({
  setup() {
    const { useModalState } = useModal();
    const { 
      isOpen, 
      component: ModalComponent, 
      size,
      props, 
      events 
    } = useModalState();

    return () => {
      if (!isOpen.value || !ModalComponent.value) return null;

      return h(AppModal, {
        isOpen: isOpen.value,
        size: size.value,
        zIndexClass: "z-[1000]"
      }, [
        h(ModalComponent.value as Component, {
          ...props.value,
          ...Object.fromEntries(
            Object.entries(events.value).map(([key, handler]) => 
              [`on${key.charAt(0).toUpperCase() + key.slice(1)}`, handler]
            )
          )
        })
      ]);
    };
  }
});
