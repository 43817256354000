<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      as="div"
      :class="`relative  ${modalClass ? modalClass : ''} ${
        zIndexClass ? zIndexClass : 'z-50'
      }`"
      @close="closeModal"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>
      <div
        tabindex="0"
        :class="`fixed h-auto md:min-h-screen md:h-screen ${
          alignRight ? 'py-0' : 'py-8'
        } inset-0 overflow-y-auto`"
      >
        <div
          :class="`flex min-h-full ${
            alignRight
              ? 'justify-end items-start h-full'
              : 'justify-center items-center px-2'
          } `"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              as="div"
              class="w-full h-full min-h-full"
              :class="sizeObject"
            >
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";

interface ModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  modalClass?: string;
  alignRight?: boolean;
  size: "sm" | "md" | "lg" | "xl";
  zIndexClass?: string;
}

const props = defineProps<ModalProps>();

const sizeObject = {
  "max-w-[250px]": props.size === "sm",
  "max-w-[350px]": props.size === "md",
  "max-w-[450px]": props.size === "lg",
  "max-w-[550px]": props.size === "xl",
};

const closeModal = () => {
  if (props.handleClose) {
    props.handleClose();
  }
};
</script>
